/*
 * QQQ - Low-code Application Framework for Engineers.
 * Copyright (C) 2021-2022.  Kingsrook, LLC
 * 651 N Broad St Ste 205 # 6917 | Middletown DE 19709 | United States
 * contact@kingsrook.com
 * https://github.com/Kingsrook/
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

.MuiDrawer-docked .MuiPaper-elevation
{
   white-space: normal;
}

.recordQuery .MuiDataGrid-virtualScrollerContent
{
   min-height: calc(100vh - 450px) !important;
}

/* we want to leave columns w/ the sortable attribute (so they have it in the column menu),
but we've turned off the click-to-sort function, so remove hand cursor */
.recordQuery .MuiDataGrid-columnHeader--sortable
{
   cursor: default !important;
}

/* Disable red outlines on clicked cells */
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within
{
   outline: none !important;
}

/* lighten & shrink cell font */
.MuiDataGrid-cell
{
   color: rgb(45, 45, 45);
   font-size: 0.85rem;
}

/* lighten & shrink header font */
.MuiDataGrid-columnHeaderTitle
{
   color: rgb(95, 95, 95);
   font-size: 0.75rem;
   font-weight: 500 !important;
}

/* tighten widths in headers */
.MuiDataGrid-iconButtonContainer
{
   width: 20px;
}

/* tighten widths in headers */
.MuiDataGrid-iconButtonContainer .MuiIconButton-root
{
   padding: 2px;
}

/* tighten widths in headers */
.MuiDataGrid-menuIcon
{
   margin-left: -5px !important;
}

/* tighten widths in headers */
.MuiDataGrid-menuIcon .MuiDataGrid-menuIconButton
{
   padding: 2px;
}

/* when checked-column is pinned, its checkboxes disappear as gray-on-gray - this helps. */
.MuiCheckbox-root .MuiSvgIcon-root
{
   background-color: white;
}

/* shrink font on in the pagination control */
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel,
.MuiTablePagination-select.MuiSelect-select.MuiSelect-standard,
.MuiDataGrid-selectedRowCount
{
   font-size: 0.85rem !important;
}

/* try to make the pagination select box look like one */
.MuiTablePagination-select
{
   border: 1px solid rgb(175 175 175) !important;
   border-radius: 5px !important;
}

/* move the green check / red x down to align with the calendar icon */
.MuiFormControl-root:has(input[type="datetime-local"]),
.MuiFormControl-root:has(input[type="date"]),
.MuiFormControl-root:has(input[type="time"]),
.MuiFormControl-root:has(.MuiInputBase-inputAdornedEnd)
{
   background-position: right 2rem center;
}

.MuiInputAdornment-sizeMedium *
{
   font-size: .875rem !important;
}

.MuiInput-input, .MuiInputLabel-root
{
   padding-left: 8px !important;
   padding-right: 8px !important;
}

.MuiInputLabel-shrink
{
   padding-top: 2px !important;
   padding-left: 2px !important;
}

.MuiDataGrid-toolbarContainer .selectionTool
{
   font-size: 14px;
}

.MuiDataGrid-toolbarContainer .pagination
{
   display: flex;
   flex-grow: 1;
   justify-content: flex-end;
}

/* Help make the radio, text, and icon wrap in a good way */
.doFullValidationRadios label
{
   display: flex;
   align-items: flex-start;
}

/* let long field names in filter dropdown wrap instead of get cut off */
.MuiDataGrid-filterForm .MuiDataGrid-filterFormColumnInput .MuiNativeSelect-select.MuiNativeSelect-standard,
.MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput .MuiNativeSelect-select.MuiNativeSelect-standard
{
   white-space: normal;
   height: auto;
}

.MuiDataGrid-filterForm
{
   align-items: flex-end;
}

.MuiDataGrid-filterFormValueInput > div
{
   height: auto;
}

/* make filter dropdowns a bit wider, less likely to need to wrap. */
.MuiDataGrid-filterForm .MuiDataGrid-filterFormColumnInput
{
   width: 200px;
}

.MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput
{
   width: 300px;
}

.MuiDataGrid-filterForm .MuiDataGrid-filterFormOperatorInput
{
   width: 150px;
}

/* Make the drop-down icon for autocompletes match the ones on the native dropdowns. */
.MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput .MuiAutocomplete-root .MuiAutocomplete-endAdornment
{
   padding-top: 4px;
}

.MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput .MuiAutocomplete-root .MuiAutocomplete-endAdornment svg
{
   height: 0.625em;
}

/* fix strange size bug on filter autocompletes */
.MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput > .MuiBox-root > .MuiBox-root:has(>.MuiAutocomplete-root)
{
   margin-bottom: 0;
   width: 100%;
}

/* google drive picker - make it be above our modal */
.picker,
.picker.picker-dialog-bg,
.picker.picker-dialog
{
   z-index: 99999;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear
{
   display: none;
   width: 0;
   height: 0;
}

input[type=search]::-ms-reveal
{
   display: none;
   width: 0;
   height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
{
   display: none;
}

/* Shrink the big margin-bottom on modal processes */
.modalProcess > .MuiBox-root > .MuiBox-root
{
   margin-bottom: 24px;
}

#chip-text-field-container > div > div
{
   background: #F8F8F8;
   height: 330px;
   padding: 10px;
   display: flex;
   flex-wrap: wrap;
}

#chip-text-field-container > div > div > textarea
{
   display: none;
}

#chip-text-field-container > div > div > div
{
   height: 100%;
   overflow: scroll;
}

.MuiChip-root
{
   padding-top: 1px !important;
}

#outlined-multiline-static
{
   padding-left: 10px;
   padding-top: 0px;
   padding-right: 10px;
   padding-bottom: 0px;
   margin-top: 10px;
}

.MuiDataGrid-toolbarContainer .MuiBadge-badge
{
   right: 7px;
   top: 5px;
   background: orange;
}

/* make tags in filter forms not be black bg w/ white text */
.MuiDataGrid-filterForm .MuiAutocomplete-tag
{
   background-color: initial !important;
   color: initial !important;
   border: 1px solid rgb(206, 212, 218);
}

.MuiDataGrid-filterForm .MuiAutocomplete-tag .MuiSvgIcon-root
{
   color: initial !important;
}

.MuiTablePagination-root .MuiTablePagination-toolbar .MuiTablePagination-select
{
   padding-right: 1.125rem !important;
}

.MuiTablePagination-root .MuiSvgIcon-root
{
   display: inline;
   color: gray;
   right: 0.125rem;
}

.devDocumentation ul > li
{
   margin-left: 30px;
}

.devDocumentation *
{
   line-height: 1.5;
}

.devDocumentation p
{
   margin-top: .5rem;
   margin-bottom: .5rem;
}

.devDocumentation code
{
   white-space: pre-wrap;
}

.MuiAutocomplete-input
{
   cursor: pointer;
}

.optimizationRulesCard
{
   font-size: 16px;
}

.optimizationRulesCard TD
{
   font-size: 14px !important;
   vertical-align: top;
}

.optimizationRulesCard A
{
   color: blue;
   font-size: 14px;
}


.auditGroupBlock
{
   position: relative;
}

.auditGroupBlock .singleAuditBlock::before
{
   content: "";
   position: absolute;
   top: 2rem;
   left: 19px;
   height: calc(100% - 60px);
   z-index: 1;
   opacity: 1;
   border-right: 0.0625rem solid rgb(222, 226, 230);
}

.scriptLogsView TD,
.scriptLogsView TH
{
   white-space: nowrap;
}

.fieldLabel
{
   color: rgb(52, 71, 103);
   font-weight: 700;
   padding-right: .5em;
   display: inline;
}

.fieldValue
{
   color: rgb(123, 128, 154);
   font-weight: 400;
   display: inline;
}

.fullScreenWidget
{
   position: fixed !important;
   left: 30px !important;
   width: calc(100vw - 60px) !important;
   top: 30px !important;
   height: calc(100vh - 60px) !important;
   z-index: 1300 !important;
}

.scriptTestForm TEXTAREA
{
   resize: vertical !important;
}

.scriptTestForm .scriptLogLines TD,
.scriptTestForm .scriptLogLines TH
{
   padding-left: 0;
   padding-right: 0;
}

.dashboardDropdownMenu #timeframe-form label
{
   font-size: 0.875rem;
}

.big-icon .material-icons-round
{
   font-size: 2rem !important;
}

.dashboard-table-actions-icon
{
   font-size: 1.5rem !important;
   position: relative;
   top: -5px;
   margin-right: 8px;
}

.dashboard-schedule-icon
{
   font-size: 1.1rem !important;
   position: relative;
   top: -5px;
   margin-right: 8px;
}

.custom-columns-panel .MuiSwitch-thumb,
.fieldListMenuBody .MuiSwitch-thumb
{
   width: 15px !important;
   height: 15px !important;
   position: relative;
   top: 3px;
}

.blobIcon
{
   margin-left: 0.25rem;
   margin-right: 0.25rem;
   cursor: pointer;
}

/* move the columns & filter panels on the query screen data grid up to not be below the column headers row */
/* todo - add a class to the query screen and qualify this like that */
.MuiDataGrid-panel
{
   top: -60px !important;
}

.MuiDataGrid-panel:has(.customFilterPanel)
{
   /* overwrite what the grid tries to do here, where it changes based on density... we always want the same. */
   /* transform: translate(274px, 305px) !important; */
   transform: translate(274px, 264px) !important;
}

/* within the data-grid, the filter-panel's container has a max-height.  mirror that, and set an overflow-y */
.MuiDataGrid-panel .customFilterPanel
{
   max-height: 450px;
   overflow-y: auto;
}

/* tighten the text in the field select dropdown in custom filters */
.customFilterPanel .MuiAutocomplete-paper
{
   line-height: 1.375;
}

/* tighten the text in the field select dropdown in custom filters */
.customFilterPanel .MuiAutocomplete-groupLabel
{
   line-height: 1.75;
}

.filterCriteriaRowColumnPopper .MuiAutocomplete-groupLabel
{
   line-height: 1.75;
   padding-top: 8px;
   padding-bottom: 8px;
}

/* taller list box */
.filterCriteriaRowColumnPopper .MuiAutocomplete-listbox
{
   max-height: 60vh;
}

/* shrink down-arrows in custom filters panel */
.customFilterPanel .booleanOperatorColumn .MuiSelect-iconStandard,
.customFilterPanel .MuiSvgIcon-root
{
   font-size: 14px !important;
}

/* make down-arrow appear in select boxes */
.customFilterPanel .booleanOperatorColumn .MuiSvgIcon-root,
.AdvancedDateTimeFilterValues .MuiSvgIcon-root
{
   display: inline-block !important;
}

/* adjust vertical padding in filter selects  */
.customFilterPanel .booleanOperatorColumn .MuiInputBase-formControl .MuiSelect-select,
.AdvancedDateTimeFilterValues .MuiInputBase-formControl .MuiSelect-select
{
   padding-bottom: calc(0.25rem + 1px) !important;
   padding-top: calc(0.25rem + 1px) !important;
}

.customFilterPanel .filterValuesColumn .MuiInputBase-inputAdornedEnd
{
   padding-right: 0 !important;
}

.customFilterPanel .filterValuesColumn .MuiInputAdornment-positionEnd button
{
   padding-left: 0;
}

.customFilterPanel .filterValuesColumn .MuiSelect-iconStandard
{
   display: inline;
}

/* change tags in any-of value fields to not be black bg with white text */
.customFilterPanel .filterValuesColumn .MuiChip-root,
.quickFilter.filterValuesColumn .MuiChip-root
{
   background: none;
   color: black;
   border: 1px solid gray;
}

/* change 'x' icon in tags in any-of value */
.customFilterPanel .filterValuesColumn .MuiChip-root .MuiChip-deleteIcon,
.quickFilter.filterValuesColumn .MuiChip-root .MuiChip-deleteIcon
{
   color: gray;
}

/* change tags in any-of value fields to not be black bg with white text */
.customFilterPanel .filterValuesColumn .MuiAutocomplete-tag,
.quickFilter.filterValuesColumn .MuiAutocomplete-tag
{
   color: #191919;
   background: none;
}

/* default hover color for the 'x' to remove a tag from an 'any-of' value was white, which made it disappear */
.customFilterPanel .filterValuesColumn .MuiAutocomplete-tag .MuiSvgIcon-root:hover,
.quickFilter.filterValuesColumn .MuiAutocomplete-tag .MuiSvgIcon-root:hover
{
   color: lightgray;
}

/* make the blue active-bottom-border not scroll in multi-value filter value panel */
/* also prevent that box from getting stupidly large; scroll well.  */
.filterValuesColumn .multiValue .Mui-focused:after
{
   border-bottom: none !important;
}

.filterValuesColumn .multiValue .Mui-focused .MuiAutocomplete-inputRoot:before
{
   border-bottom: none !important;
}

.filterValuesColumn .multiValue .MuiAutocomplete-inputRoot.Mui-focused
{
   border-bottom: 2px solid #0062FF;
   max-height: 150px;
   overflow-x: hidden;
   overflow-y: auto;
}


.DynamicSelectPopper ul
{
   padding: 0;
}

.DynamicSelectPopper ul li.MuiAutocomplete-option
{
   padding-left: 0.25rem;
   padding-right: 0.25rem;
}

/* file-select box in script editor & viewer - make it look tabby */
.scriptEditor .selectedFileTab div.MuiSelect-select,
.scriptViewer .selectedFileTab div.MuiSelect-select
{
   padding: 0.25rem 1.5rem 0.25rem 1rem !important;
   border: 1px solid lightgray;
   border-radius: 0.375rem 0.375rem 0 0;
}

.scriptEditor .selectedFileTab,
.scriptViewer .selectedFileTab
{
   margin-bottom: -1px;
}

/* show the down-arrow in the file-select box in script editor & viewer */
.scriptEditor .selectedFileTab .MuiSelect-iconStandard,
.scriptViewer .selectedFileTab .MuiSelect-iconStandard
{
   display: inline;
   right: .5rem
}

/* help-content */
.helpContent
{
   color: #757575;
}

.helpContent .header
{
   color: #212121;
   font-weight: 500;
   display: block;
   margin-bottom: 0.25rem;
}

.MuiTooltip-tooltip .helpContent P + P,
.MuiTooltip-tooltip .helpContent UL + P
{
   margin-top: 1rem;
}

.helpContent UL
{
   margin-left: 1rem;
}

/* for query screen column-header tooltips, move them up a little bit, to be more closely attached to the text. */
.dataGridHeaderTooltip
{
   top: -1.25rem;
}

/* when grid contents weren't filling the height of the screen, the gray panel for pinned columns
 was stretching to most of the grid height, but it wasn't the full height and so looked a little
 broken.  just turing off this min height changes to not try to stretch at all, and is not broken. */
.MuiDataGrid-pinnedColumns
{
   min-height: unset !important;
}

.MuiDataGrid-columnHeaders
{
   scrollbar-gutter: stable;
}

/* new style for toggle buttons */
.MuiToggleButtonGroup-root
{
   padding: 0.25rem;
   border: 1px solid #BDBDBD;
   border-radius: 0.5rem !important;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root
{
   text-transform: none;
   font-size: 0.75rem;
   color: black;
   font-weight: 600;
   border-radius: 0.375rem !important; /* overriding left/right edge overrides for first/last */
   border: none;
   flex: 1 1 0px;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.Mui-selected
{
   background: rgba(117, 117, 117, 0.20);
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.Mui-disabled
{
   border: none;
}

.entityForm h5,
.recordView h5
{
   font-weight: 500;
}

.recordView .widget
{
   padding: 24px;
}

.entityForm .widget
{
   padding: 24px;
}

.MuiPickersDay-root.Mui-selected, .MuiPickersDay-root.MuiPickersDay-dayWithMargin:hover
{
   color: white;
   background-color: #0062FF !important;
}

/* several styles below here for user-defined alert inside helpContent */
.helpContentAlert
{
   padding: 6px 16px;
   font-size: 1rem;
   font-weight: 300;
   line-height: 1.6;
   display: flex;
}

.helpContentAlert .MuiAlert-icon
{
   display: flex;
   margin-right: 12px;
   padding: 7px 0;
   font-size: 22px;
   opacity: 0.9;
}

.helpContentAlert .MuiAlert-icon .material-icons-round
{
   display: inline-block;
   width: 1em;
   height: 1em;
}

.helpContentAlert .MuiAlert-message
{
   padding: 8px 0;
}

.helpContentAlert.info,
.banner.info
{
   background-color: rgb(234, 242, 255);
   color: rgb(20, 51, 102);
}

.helpContentAlert.info .MuiAlert-icon .material-icons-round,
.banner.info .MuiAlert-icon .material-icons-round
{
   color: #0062FF;
}

.helpContentAlert.success,
.banner.success
{
   background-color: rgb(240, 248, 241);
   color: rgb(44, 76, 46);
}

.helpContentAlert.success .MuiAlert-icon .material-icons-round,
.banner.success .MuiAlert-icon .material-icons-round
{
   color: #4CAF50;
}

.helpContentAlert.warning,
.banner.warning
{
   background-color: rgb(254, 245, 234);
   color: rgb(100, 65, 20);
}

.helpContentAlert.warning .MuiAlert-icon .material-icons-round,
.banner.warning .MuiAlert-icon .material-icons-round
{
   color: #fb8c00;
}

.helpContentAlert.error,
.banner.error
{
   background-color: rgb(254, 239, 238);
   color: rgb(98, 41, 37);
}

.helpContentAlert.error .MuiAlert-icon .material-icons-round,
.banner.error .MuiAlert-icon .material-icons-round
{
   color: #F44335;
}

/* the alert widget, was built with minimal (no?) margins, for embedding in
 a parent widget; but for using it on a process, give it some breathing room */
.processRun .widget .MuiAlert-root
{
   margin: 2rem 1rem;
}

/* default styles for a block widget overlay */
.blockWidgetOverlay
{
   font-weight: 400;
   position: relative;
   top: 15px;
   height: 0;
   display: flex;
   font-size: 14px;
   flex-direction: column;
   align-items: center;
}
.blockWidgetOverlay a
{
   color: #0062FF !important;
}

@media (min-width: 1400px)
{
   .recordSidebar
   {
      max-width: 400px !important;
   }

   .recordWithSidebar
   {
      max-width: 100% !important;
      flex-grow: 1 !important;
   }
}